import styled from 'styled-components';

const Button = styled.button`
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &:disabled {
    opacity: 0.65;
    cursor: not-allowed;
  }
`;

export const PrimaryButton = styled(Button)`
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;

  &:hover {
    background-color: #0056b3;
    border-color: #0056b3;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
  }
`;

export const DangerButton = styled(Button)`
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;

  &:hover {
    background-color: #c82333;
    border-color: #bd2130;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(225, 83, 97, 0.5);
  }
`;

export const WarningButton = styled(Button)`
  color: #212529;
  background-color: #ffc107;
  border-color: #ffc107;

  &:hover {
    background-color: #e0a800;
    border-color: #d39e00;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(222, 170, 12, 0.5);
  }
`;

export const SuccessButton = styled(Button)`
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;

  &:hover {
    background-color: #218838;
    border-color: #1e7e34;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(72, 180, 97, 0.5);
  }
`;

export const InfoButton = styled(Button)`
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;

  &:hover {
    background-color: #138496;
    border-color: #117a8b;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(58, 176, 195, 0.5);
  }
`;

export const DarkButton = styled(Button)`
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;

  &:hover {
    background-color: #23272b;
    border-color: #1d2124;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(82, 88, 93, 0.5);
  }
`;

export const LightButton = styled(Button)`
  color: #212529;
  background-color: #f8f9fa;
  border-color: black;

  &:hover {
    background-color: #e2e6ea;
    border-color: #dae0e5;
  }

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(216, 217, 219, 0.5);
  }
`;

export const LinkButton = styled(Button)`
  color: #007bff;
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
    text-decoration: none;
  }

  &:focus {
    box-shadow: none;
  }
`;
