import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type LoaderState = {
  isLoading: boolean;
};

const initialLoaderState: LoaderState = {
  isLoading: false,
};


const loaderSlice = createSlice({
  name: 'loader',
  initialState: initialLoaderState,
  reducers: {
    showLoader: (state: LoaderState) => {
      state.isLoading = true;
    },
    hideLoader: (state: LoaderState) => {
      state.isLoading = false;
    },
  },
});

export const { showLoader, hideLoader } = loaderSlice.actions;
export default loaderSlice.reducer;