/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { ChangeEvent, FormEvent, useState, useCallback, useEffect } from 'react';
import { makeArray, validateField, checkIfArrayHasValue } from 'utilities';
import useAxios, { MethodTypes } from './useAxios';

export interface SendObject {
  url: string;
  data: object;
  method: MethodTypes;
}

type UploadFile = {
  file: File;
  name: string;
};

type SubmitForm = (props: SendObject) => void;

const useForm = <T extends {}, R>(
  url: string,
  method: MethodTypes,
  fieldValues?: T,
  // @ts-ignore
  callback?: Function
) => {
  const [values, setValues] = useState(fieldValues); // the fields values that we pass in
  const [errors, setErrors] = useState<Partial<Record<keyof T, string>>>({}); // errors
  const [submitObj, setSubmitObj] = useState({ url } as SendObject); // onbject that will be submitted in the request
  const [isSubmitting, setIsSubmitting] = useState(false); // state that tells us if we are executing a submitt.
  const [callAxiosFn, axiosState] = useAxios<R>(); // a callback that we can execute once the request is done
  const [callbackExecute, setcallbackExecute] = useState(false); // check to see if the callback has been executed
  const [forcetrigger, setForcetrigger] = useState(false); // trigger a change on a specific field.
  const [files, setFiles] = useState<Array<UploadFile>>([]);

  const buildCallingObject = (obj: SendObject): object => {
    // adding support for get and other method types.
    // Get adds query params and needs the "params" param instead of data

    if (files.length > 0) {
      const Fdata = new FormData();

      files.forEach((file) => {
        Fdata.append('media', file.file, file.name); // Use 'files' as the field name
      });

      const returnObj = {
        method,
        url: obj.url,
        data: Fdata,
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      return returnObj;
    } else {
      const callingObj =
        method === MethodTypes.GET
          ? {
              method,
              url: obj.url,
              params: obj.data, // note diff here
            }
          : {
              method,
              url: obj.url,
              data: obj.data, // note diff here
            };

      return callingObj;
    }
  };

  const submitForm: SubmitForm = (obj: SendObject) => {
    const callingObj = buildCallingObject(obj);
    callAxiosFn(callingObj);
  };

  useEffect(() => {
    if (!checkIfArrayHasValue(makeArray(errors)) && isSubmitting) {
      submitForm(submitObj);
    }

    setIsSubmitting(false);
  }, [errors, isSubmitting]);

  useEffect(() => {
    if (axiosState.data && callback && !callbackExecute) {
      callback(values);
      setcallbackExecute(true);
    }
  }, [axiosState, values, callbackExecute]);

  useEffect(() => {
    if (!axiosState.data) {
      setcallbackExecute(false);
    }
  }, [axiosState]);

  // sets the values.
  const valuesHandler = useCallback(
    (name: string, value: string | number | boolean) => {
      setValues(() => ({
        ...values,
        [name]: value,
      }));
    },
    [values]
  );

  const errorHandler = useCallback(
    (name: string, value: string) => {
      setErrors((prevErrors) => ({ ...prevErrors, ...validateField<T>(name, value) }));
    },
    [errors]
  );

  const validateAllFields = useCallback(() => {
    // for going through every value and calling the onblur validation,
    // note values shall not be turned into array since it is used as post obj.
    const res = makeArray(values);
    res.map((prop: any) => errorHandler(prop[0], prop[1]));
  }, [errors, values]);

  const formHandler = useCallback(() => {
    validateAllFields(); // validate all fields.

    setSubmitObj((prevObj) => ({ ...prevObj, data: values })); // set object to submit
    setIsSubmitting(true); // tell the hook to execute the request
  }, [submitObj, values, isSubmitting]);

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
      const { name, value } = event.target;
      errorHandler(name, value);
    },
    [errorHandler]
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      event.persist();

      if (event.target.files && event.target.files.length > 0) {
        const allFiles: UploadFile[] = Array.from(event.target.files).map((f) => {
          return {
            file: f,
            name: f.name,
          };
        });

        setFiles((prevFiles) => [...prevFiles, ...allFiles]);
      }

      const value =
        event.target.type === 'checkbox' ? event.target.checked.toString() : event.target.value;
      valuesHandler(event.currentTarget.name, value);
    },
    [values, errors]
  );

  const handleSubmit = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      if (event) event.preventDefault();
      // for going through every value and calling the onblur validation,
      // note values shall not be turned into array since it is used as post obj
      formHandler();
    },
    [submitObj, values, isSubmitting]
  );

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent) => {
      if (event) event.persist();
      if (event.key === 'Enter') {
        formHandler();
      }
    },
    [submitObj, values, isSubmitting]
  );

  // trigger value change on a specific fields via code.
  const triggerValueChange = useCallback(
    (name: string, value: string | number) => {
      setValues(() => ({
        ...values,
        [name]: value,
      }));
      setForcetrigger(true);
    },
    [values, forcetrigger]
  );

  useEffect(() => {
    if (forcetrigger) {
      setForcetrigger(false);
      formHandler();
    }
  }, [forcetrigger]);

  return {
    handleChange,
    validateAllFields,
    values,
    errors,
    handleBlur,
    handleSubmit,
    handleKeyDown,
    setValues,
    axiosState,
    triggerValueChange,
  };
};

export default useForm;
