const constants = {
  LOG_LEVEL: process.env.REACT_APP_LOG_LEVEL || 'info',
  GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID || '',
  ENV: process.env.NODE_ENV,
};

export const backendUrl =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:4000/api/'
    : `${window.location.origin}/api/`;

export default constants;
