import { useEffect, useRef } from 'react';
import { useIsCompact } from 'utilities/breakpoints';

type Props = {
  shouldRender: boolean;
  htmlRef: React.RefObject<HTMLDivElement>;
  force?: boolean;
};

const useLayout = (props: Props) => {
  const { shouldRender, htmlRef, force } = props;
  const fadeTimeoutRef = useRef<number | null>(null); // To handle fade effect
  const displayTimeoutRef = useRef<number | null>(null); // To handle hiding the display

  const isCompact = useIsCompact();

  useEffect(() => {
    const overlay = htmlRef.current;
    if (isCompact || force) {
      if (overlay) {
        if (shouldRender) {
          // Set initial opacity and visibility before changing display to prevent flash
          overlay.style.opacity = '0';
          overlay.style.visibility = 'hidden';
          overlay.style.display = 'block'; // Show the overlay (still invisible)
          if (force) {
            overlay.style.zIndex = '10000';
            overlay.style.display = 'flex';
          }


          // Now apply the fade-in effect
          fadeTimeoutRef.current = window.setTimeout(() => {
            overlay.style.opacity = '1';
            overlay.style.visibility = 'visible';
          }, 10); // Slight delay to trigger the CSS transition
        } else {
          // Fade out the overlay and then hide it
          fadeTimeoutRef.current = window.setTimeout(() => {
            overlay.style.opacity = '0';
            overlay.style.visibility = 'hidden';

            // Delay hiding the display until the transition ends
            displayTimeoutRef.current = window.setTimeout(() => {
              overlay.style.display = 'none';
            }, 100); // Match this to the duration of the opacity transition
          }, 150); // Slight delay to start fading out
        }
      }
    } else {
      overlay.style.opacity = '0';
      overlay.style.visibility = 'hidden';
      overlay.style.display = 'none';
    }

    // Cleanup function to clear any pending timeouts when component unmounts
    return () => {
      if (displayTimeoutRef.current) {
        clearTimeout(displayTimeoutRef.current);
        displayTimeoutRef.current = null;
      }

      if (fadeTimeoutRef.current) {
        clearTimeout(fadeTimeoutRef.current);
        fadeTimeoutRef.current = null;
      }
    };
  }, [shouldRender, isCompact, htmlRef]);
};

export default useLayout;
