import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Breakpoint } from 'utilities/breakpoints';

type CurrentBreakPointState = {
  currentBreakPoint: number;
};

const initialState: CurrentBreakPointState = {
  currentBreakPoint: 3,
};

export const breakPointSlice = createSlice({
  name: 'breakPoint',
  initialState,
  reducers: {
    switchBreakpoint: (state, action: PayloadAction<Breakpoint>) => {
      state.currentBreakPoint = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { switchBreakpoint } = breakPointSlice.actions;

export default breakPointSlice.reducer;
