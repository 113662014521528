import { Preview } from 'components/Media';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import useForm from 'hooks/useForm';
import useAxios, { MethodTypes } from 'hooks/useAxios';
import { generateScreenshots } from 'utilities';
import { ImageUrlViewModel, UrlViewModel } from 'components/Media/Image';
import { backendUrl } from 'config/constants';
import { LightButton, SuccessButton } from 'Shared/Components/Button';
import { useDispatch } from 'react-redux';
import { hideLoader, showLoader } from 'redux-tk/slices/loaderSlice';

export enum TrainingType {
  Bicep = 'Bicep',
  Tricep = 'Tricep',
  Chest = 'Chest',
  Back = 'Back',
  Legs = 'Legs',
  Abs = 'Abs',
  Sholders = 'Sholders',
}

export type TrainingFile = {
  file: File;
  src: UrlViewModel;
  name: string;
  trainingType?: TrainingType;
};

export const Upload = () => {
  const [files, setFiles] = useState<TrainingFile[]>([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [screenshots, setScreenshots] = useState<string[]>([]);
  const [videoUrl, setVideoUrl] = useState<string | null>(null);
  const { handleChange, handleSubmit } = useForm(`${backendUrl}upload`, MethodTypes.POST);
  const [message, setMessage] = useState<string | null>(null);
  const messageTimeout = useRef<number | null>(null);
  const [callAxiosFn, axiosState] = useAxios();

  // useeffect when need to send files.

  const dispatch = useDispatch();

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const updateFile = (updatedFile: TrainingFile) => {
    setFiles((prevFiles) =>
      prevFiles.map((file) => (file.name === updatedFile.name ? updatedFile : file))
    );
  };

  const delteFile = (fileToDelete: TrainingFile) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileToDelete.name));
  };

  //   // const screenShoots = await generateScreenshots(files);
  //   // setScreenshots(screenShoots);

  // const uploadFiles = async (fileList: File[]) => {
  //   const filesArray = fileList;
  //   const uploads: TrainingFile[] = [];

  //   filesArray.forEach((file) => {
  //     const reader = new FileReader();
  //     const img = new Image();

  //     reader.onload = (ev: ProgressEvent<FileReader>) => {
  //       if (ev.target && ev.target.result) {
  //         img.src = URL.createObjectURL(file);
  //         img.onload = () => {
  //           const imageViewModel: UrlViewModel = {
  //             url: img.src,
  //             width: img.width,
  //             height: img.height,
  //             intrinsicSize: `${img.width}x${img.height}`,
  //           };
  //           const upload: TrainingFile = {
  //             file,
  //             src: imageViewModel,
  //             name: file.name,
  //           };
  //           uploads.push(upload);
  //           if (uploads.length === filesArray.length) {
  //             setFiles((prevFiles) => [...prevFiles, ...uploads]);
  //           }
  //         };
  //       }
  //     };

  //     reader.readAsDataURL(file);
  //   });
  // };

  const uploadFiles = async (fileList: File[]) => {
    const filesArray = fileList;
    const uploads: TrainingFile[] = [];

    filesArray.forEach((file) => {
      if (file.type.startsWith('image/')) {
        // Handle image files
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          const imageViewModel: UrlViewModel = {
            url: img.src,
            width: img.width,
            height: img.height,
            intrinsicSize: `${img.width}x${img.height}`,
          };
          const upload: TrainingFile = {
            file,
            src: imageViewModel,
            name: file.name,
          };
          uploads.push(upload);

          // Update files state when all files are processed
          if (uploads.length === filesArray.length) {
            setFiles((prevFiles) => [...prevFiles, ...uploads]);
          }
        };
      } else if (file.type.startsWith('video/')) {
        // Handle video files
        const video = document.createElement('video');
        video.src = URL.createObjectURL(file);

        video.onloadedmetadata = () => {
          const videoViewModel: UrlViewModel = {
            url: video.src,
            width: video.videoWidth,
            height: video.videoHeight,
            intrinsicSize: `${video.videoWidth}x${video.videoHeight}`,
          };
          const upload: TrainingFile = {
            file,
            src: videoViewModel,
            name: file.name,
          };
          uploads.push(upload);

          // Update files state when all files are processed
          if (uploads.length === filesArray.length) {
            setFiles((prevFiles) => [...prevFiles, ...uploads]);
          }
        };
      }
    });
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();

    if (messageTimeout.current) {
      clearTimeout(messageTimeout.current);
    }

    const droppedFiles = Array.from(event.dataTransfer.files);

    // Remove duplicate files (within dropped files and compared to existing files)
    const uniqueFiles = droppedFiles.filter(
      (file, index, self) =>
        self.findIndex((f) => f.name === file.name) === index &&
        !files.some((existingFile) => existingFile.name === file.name)
    );

    if (uniqueFiles.length < droppedFiles.length) {
      setMessage('Duplicate files detected! Only one of each will be uploaded.');

      // Set a new timeout to clear the message after 10 seconds
      messageTimeout.current = window.setTimeout(() => setMessage(null), 10000);
    }

    uploadFiles(uniqueFiles);
  };

  const handleSave = useCallback(() => {
    if (!files.length) return;

    const Fdata = new FormData();
    files.forEach((file) => {
      Fdata.append('media', file.file, file.name);
      Fdata.append('trainingType', file.trainingType);
    });

    const postObj = {
      method: MethodTypes.POST,
      url: `${backendUrl}upload`,
      data: Fdata,
      headers: {
        'content-type': 'multipart/form-data',
      },
    };

    dispatch(showLoader());

    callAxiosFn(postObj);
  }, [files, dispatch, callAxiosFn]);

  useEffect(() => {
    if (!axiosState.loading) {
      dispatch(hideLoader());
    }
  }, [axiosState, dispatch]);

  return (
    <Container>
      <UploadContainer>
        <Main>
          <h2>Add a new file</h2>
          <UploadArea onClick={handleClick} onDragOver={handleDragOver} onDrop={handleDrop}>
            <p>Click or drag files here to upload</p>
          </UploadArea>
          <HiddenInput
            ref={fileInputRef}
            type="file"
            multiple
            onChange={(e) => uploadFiles(Array.from(e.target.files!))}
          />
          {message && <Message>{message}</Message>}
        </Main>
      </UploadContainer>
      {files.length > 0 && (
        <FilesContainer>
          {files.map((file, index) => (
            <>
              <Preview
                src={file.src}
                name={file.name}
                file={file}
                onFileChange={updateFile}
                onDelete={delteFile}
              />
              {/* <div>Want a cover image?</div>
              <Form.Check reverse label="2" name="group1" type={'radio'} /> */}
            </>
          ))}
        </FilesContainer>
      )}

      <ActionArea>
        <SaveButton onClick={handleSave}>Upload</SaveButton>
        <CancelButton
          onClick={() => {
            setFiles([]);
          }}
        >
          Cancel
        </CancelButton>
      </ActionArea>
    </Container>
  );
};

const HiddenInput = styled.input.attrs({
  type: 'file',
  multiple: true, // Ensure multiple is enabled here
})`
  display: none;
`;

const UploadArea = styled.div`
  display: flex;
  border: 2px dashed #ccc;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f8f8f8;
  }

  height: 220px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActionArea = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: ${({ theme }) => (theme.isCompact ? 'column' : 'row')};
  align-items: ${({ theme }) => (theme.isCompact ? 'center' : 'center')};
  margin: 20px;
`;

const SaveButton = styled(SuccessButton)`
  height:fit-content;
  width: ${({ theme }) => (theme.isCompact ? `100%` : '33%')};
  margin:auto: 0;
  margin:5px;
`;
const CancelButton = styled(LightButton)`
  height:fit-content;
  width: ${({ theme }) => (theme.isCompact ? `100%` : '33%')};
  margin:auto: 0;
  margin:5px;
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const FilesContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: rgba(245, 237, 217, 0.44);
  justify-content: ${({ theme }) => (theme.isTablet ? `flex-start` : 'center')};
  padding: 10px;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const Main = styled.div`
  margin: 20px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.85);
  padding: 15px;
  border: 1px solid rgba(161, 108, 40, 1);
  border-radius: 20px;
  text-align: center;
`;

const Message = styled.div`
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
`;
