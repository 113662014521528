import i18n, { ResourceLanguage } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { SupportedLocale } from 'utilities/constants';
import enGB from './locales/en-GB/translation.json';
import svSE from './locales/sv-SE/translation.json';
import enSE from './locales/en-SE/translation.json';

const resources: { [key in SupportedLocale]: ResourceLanguage } = {
  'en-GB': { translation: enGB },
  'sv-SE': { translation: svSE },
  'en-SE': { translation: enSE },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    debug: true,
    lng: 'en-GB',
    fallbackLng: 'en-GB',
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
