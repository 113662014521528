import { configureStore } from '@reduxjs/toolkit';
import breakPointReducer from './slices/breakpointSlice';
import userReducer from './slices/userSlice';
import loadingReducer from './slices/loaderSlice';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';

const persistConfig = {
  key: 'user',
  storage,
};

const persistedUserReducer = persistReducer(persistConfig, userReducer);

export const store = configureStore({
  reducer: {
    currentBreakPoint: breakPointReducer,
    currentUser: persistedUserReducer,
    isLoading: loadingReducer
  },
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
