import styled from 'styled-components';


export const MainPage = () => {
  return (
    <Container>
      Start of the fancy journey
    </Container>
  );
};


const Container = styled.div`
  background-color: white;
  color: ${({ theme }) => theme.mainTextColor};
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width:100%;
`;


