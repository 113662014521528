/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
export const makeArray = (obj: any) => Array.from(Object.keys(obj), (k) => [`${k}`, obj[k]]);

export const checkIfArrayHasValue = (array: Array<Object>): boolean => {
  let hasValue = false;
  // eslint-disable-next-line array-callback-return
  array.map((prop: any): void => {
    if (prop[1] !== '') hasValue = true;
  });

  return hasValue;
};

export const MakeChunkArray = (array: Array<any>, number: number, prop?: any) => {
  if (!array.length) {
    return [];
  }

  if (prop)
    return array
      .sort((a, b) => a[prop] - b[prop])
      .slice(0, ((array.length + number - 1) / number) | 0)
      .map((c, i) => array.slice(number * i, number * i + number));

  return array
    .slice(0, ((array.length + number - 1) / number) | 0)
    .map((c, i) => array.slice(number * i, number * i + number));
};

export const validateField = <T extends {}>(fieldName: string, value: string): T => {
  let errors = {} as unknown;

  switch (fieldName) {
    case 'text':
      errors = {
        [fieldName]: value.trim() === '' ? 'please eneter a value' : '',
      };
      break;
    default:
      errors = {
        [fieldName]: value.trim() === '' ? 'please eneter a value' : '',
      };
      break;
  }

  return errors as T;
};

export const generateScreenshots = async (videoFiles: Array<File>): Promise<string[]> => {
  return Promise.all(
    videoFiles.map((file) => {
      return new Promise<string>((resolve, reject) => {
        const video = document.createElement('video');
        video.preload = 'metadata';
        video.src = URL.createObjectURL(file);

        // When video metadata is loaded, seek to 3 seconds
        video.onloadedmetadata = () => {
          video.currentTime = 3;
        };

        // When video has seeked to the time we've set
        video.onseeked = () => {
          const canvas = document.createElement('canvas');
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;

          const ctx = canvas.getContext('2d');
          if (!ctx) {
            reject(new Error('Failed to get canvas context'));
            return;
          }

          ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

          // You can also convert the canvas to a blob and then use URL.createObjectURL(blob) for creating an object URL.
          const screenshot = canvas.toDataURL('image/jpeg');

          resolve(screenshot);

          // Clean up memory by revoking the object URL and releasing any internal reference the video might hold
          URL.revokeObjectURL(video.src);
          video.src = '';
        };

        // Error handling for the video loading process
        video.onerror = (err) => {
          reject(err);
        };
      });
    })
  );
};
