import { ReactComponent as PlaySvg } from './glyphs/youtube.svg';
import styled from 'styled-components';
import {
  Home,
  Info,
  Mail,
  LogIn,
  LogOut,
  Upload,
  Image,
  UserPlus,
  Edit,
  X,
  Edit2,
  Trash2,
} from 'react-feather';

export const iconStyled = (Component: React.ComponentType<React.SVGAttributes<SVGElement>>) =>
  styled(Component)({
    display: 'inline-flex',
    width: '2em',
    height: '2em',
    fontSize: '12px',
    cursor: 'pointer',
  });

export default function factory(Glyph: React.ComponentType<React.SVGAttributes<SVGElement>>) {
  const StyledGlyph = iconStyled(Glyph);

  return (props: React.SVGAttributes<SVGElement>) => (
    <StyledGlyph width="1em" height="1em" {...props} />
  );
}

export const Play = factory(PlaySvg);

//Feather
export const HomeFeather = factory(Home);
export const InfoFeather = factory(Info);
export const MailFeather = factory(Mail);
export const LogInFeather = factory(LogIn);
export const LogOutFeather = factory(LogOut);

export const UploadFeather = factory(Upload);
export const ImageFeather = factory(Image);
export const UserPlusFeather = factory(UserPlus);
export const EditFeatther = factory(Edit);
export const EditFeattherTwo = factory(Edit2);
export const XFeatther = factory(X);
export const TrashFeatther = factory(Trash2);
