import { Overlay, Loader } from 'Shared/Components';
import styled from 'styled-components';

const OverlayWithLoader = ({ overlayRef }: { overlayRef: React.Ref<HTMLDivElement> }) => (
  <StyledOverlay ref={overlayRef}>
    <Loader />
  </StyledOverlay>
);

const StyledOverlay = styled(Overlay)`
  justify-content: center;
  align-items: center;
`;

export default OverlayWithLoader;
