import React from 'react';
import styled from 'styled-components';
import Strength from 'assets/images/Strength.jpg';
import Core from 'assets/images/CoreLift.svg';
import Gym from 'assets/images/Perica.webp';
import Abs from 'assets/images/FinalAbs.webp';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';

const events = [{ title: 'Perica', start: new Date() }];

function renderEventContent(eventInfo) {
  return (
    <>
      <b>{eventInfo.timeText}</b>
      <i>{eventInfo.event.title}</i>
    </>
  );
}

const handleDateClick = (arg) => {
  // bind with an arrow function
  alert(arg.dateStr);
};

const CardComp: React.FC = () => {
  return (
    <CardContainer>
      <Card>
        <ImageHolder>
          <Face>
            <Image src={Core}></Image>
          </Face>
        </ImageHolder>
        <Text>
          <Header>Core</Header>
        </Text>

        <Button>More details</Button>
      </Card>
      <Card>
        <ImageHolder>
          <Face>
            <Image src={Strength}></Image>
          </Face>
        </ImageHolder>
        <Text>
          <Header>Strength</Header>
        </Text>
        <Button>More details</Button>
      </Card>
      <Card>
        <ImageHolder>
          <Face>
            <Image src={Abs}></Image>
          </Face>
        </ImageHolder>
        <Text>
          <Header>Abbs</Header>
        </Text>
        <Button>More details</Button>
      </Card>
    </CardContainer>
  );
};

const flatFunction = () => {
  const arr = [1, 2, 3, 4, [5, 6, 7, [8, 9, 10]]];

  const arrFlatOne = arr.flat();

  console.log('one', arrFlatOne);

  // note you could use Infinity when you dont know the depth but it is a costly process.
  const arrFlatTwo = arr.flat(2);

  console.log('two', arrFlatTwo);
};

const flatMapFunction = () => {
  const parents = [
    {
      id: 1,
      name: 'Parent 1',
      children: [
        { id: 101, name: 'Child 1.1' },
        { id: 102, name: 'Child 1.2' },
      ],
    },
    {
      id: 2,
      name: 'Parent 2',
      children: [
        { id: 201, name: 'Child 2.1' },
        { id: 202, name: 'Child 2.2' },
      ],
    },
  ];

  const allChildrenWithParents = parents.flatMap((parent) =>
    parent.children.map((child) => ({
      ...child,
      parentId: parent.id,
      parentName: parent.name,
    }))
  );

  console.log(allChildrenWithParents);
};

const reduceFunction = () => {
  const numbers = [1, 2, 3, 4];

  // (acc, current) => acc + current; carefull how you read this, this part is the function, the 0 is a second param

  const sum = numbers.reduce((accumulator, current) => accumulator + current, 0);
  // sum is 10
};

const mapFunction = () => {
  const map = new Map();

  // Add key-value pairs to the Map
  map.set('name', 'John Doe');
  map.set('age', 30);
  map.set('job', 'Software Developer');

  // Retrieve values from the Map
  console.log(map.get('name')); // Output: John Doe
  console.log(map.get('age')); // Output: 30

  // Check if a key exists
  console.log(map.has('job')); // Output: true
  console.log(map.has('address')); // Output: false

  // Get the size of the Map
  console.log(map.size); // Output: 3

  // Iterate over the Map
  map.forEach((value, key) => {
    console.log(`${key}: ${value}`);
  });
};

export const StartPage = () => {
  flatFunction();
  flatMapFunction();
  reduceFunction();
  mapFunction();

  return (
    <Container>
      <ImageWrapper>{/* <Image src={Gym}></Image> */}</ImageWrapper>
      <CardComp />
      <StyledDiv>Text content</StyledDiv>

      {/* <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        weekends={true}
        events={events}
        eventContent={renderEventContent}
        // dateClick={handleDateClick}
      /> */}
    </Container>
  );
};

const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Card = styled.div`
  display: flex;
  height: 500px;
  width: ${({ theme }) => (theme.isCompact ? '100%' : '32%')};
  justify-content: center;
  text-align: center;
  flex-direction: column;
  border-radius: 12px;
  align-items: center;
  margin-left: ${({ theme }) => (theme.isCompact ? '0px' : '10px')};
  margin-bottom: ${({ theme }) => (theme.isCompact ? '10px' : '0px')};
  border-bottom: ${({ theme }) => (theme.isCompact ? '1px solid black' : '')};
`;

const ImageHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  align-items: center;

  margin-bottom: 20px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
`;

const Button = styled.button`
  border-radius: 25px;
  color: white;
  background: black;
  padding: 8px;
  width: 40%;
`;

const Face = styled.div`
  background-color: grey;
  border-radius: 100%;
  border: 1px solid black;
  max-width: ${({ theme }) => (theme.isCompact ? '160px' : '150px')};
  margin-top: 50px;
  max-height: 200px;
  width: ${({ theme }) => (theme.isCompact ? '160px' : '150px')};
  height: 200px;
  overflow: hidden;
`;

const Header = styled.h2`
  color: black;
`;

const Container = styled.div`
  background-color: white)};
  color: ${({ theme }) => theme.mainTextColor};
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const ImageWrapper = styled.div`
  background-image: url(${Gym});
  background-size: cover;
  background-position: center;
  width: 100%; /* or any specific width */
  height: 500px; /* or any desired height */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const MainContent = styled.div`
  background-color: lightblue;
  color: ${({ theme }) => theme.mainTextColor};
  border: 1px solid black;
  height: 500px;
  width: 100%;
  text-align: center;

  clip-path: polygon(0 0, 100% 0, calc(100% - 20px) 100%, 0 100%);
`;

const StyledDiv = styled.div`
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: lightblue;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
`;
