import React, { lazy, Suspense, useMemo, useRef } from 'react';
import { allRoutes, RouteType } from 'routes/AllRoutes';
import 'bootstrap/dist/css/bootstrap.min.css';
import { setupResizeListener } from 'utilities/breakpoints';
import { store, RootState } from './redux-tk/store';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { allUserRoles } from 'utilities/constants';
import useLayout from 'hooks/useLayout';
import OverlayWithLoader from 'components/Overlay';

const Layout = lazy(() => import('components/Layout/Layout'));
const PrivateLayout = lazy(() => import('components/Layout/PrivateLayout'));
const EditLayout = lazy(() => import('components/Layout/EditLayout'));

const App: React.FC = () => {
  setupResizeListener(store);

  const overlayRef = useRef<HTMLDivElement | null>(null);
  const user = useSelector((state: RootState) => state.currentUser.user);
  const loader = useSelector((state: RootState) => state.isLoading);

  const filteredRoutes = useMemo(
    () =>
      allRoutes.filter(
        (f) =>
          (f.type === RouteType.Public && user.Id === 0) ||
          (f.type === RouteType.Private && user.Id !== 0) ||
          (f.type === RouteType.Edit && user.role === allUserRoles.Admin)
      ),
    [user]
  );

  const UseLayout = useMemo(
    () => (user.Id === 0 ? Layout : user.role === allUserRoles.Admin ? EditLayout : PrivateLayout),
    [user]
  );


  useLayout({ shouldRender: loader.isLoading, htmlRef: overlayRef, force: true });

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Main role="main">
          <UseLayout routes={filteredRoutes} />
        </Main>
      </Suspense>
      <OverlayWithLoader overlayRef={overlayRef} />
    </>
  );
};

export default App;

const Main = styled.div`
  box-shadow: inset 0 0 0 100vh rgba(0, 0, 0, 0.7);
`;
