import { TrainingType } from 'pages/Upload';
import { useEffect, useState } from 'react';
import { Cover, Tags, TagsContainer } from 'Shared/Components';
import { UrlViewModel } from 'utilities/breakpoints';

type PropType = {
  src: UrlViewModel;
  fileName: string;
  trainingType: TrainingType;
  isEdit: boolean;
};

const ImagePreviewUp: React.FC<PropType> = (props) => {
  const { src, trainingType, isEdit, fileName } = props;
  const [fade, setFade] = useState(true);

  useEffect(() => {
    setFade(false); // Start fade-out
    const timeout = setTimeout(() => {
      setFade(true); // Start fade-in
    }, 500); // Match this to the CSS transition duration

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Cover isEdit={isEdit} backgroundImage={src.url} fade={fade}>
      {!isEdit && (
        <TagsContainer>
          <Tags>{fileName}</Tags>
          <Tags>{trainingType}</Tags>
        </TagsContainer>
      )}
    </Cover>
  );
};

export default ImagePreviewUp;
