import React from 'react';
import styled from 'styled-components';

export const NotFoundPage: React.FC = () => (
  <CardContainer>
    The page you were looking for could not be found. Take a cup of coffe and rethink your
    decision.
  </CardContainer>
);


const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 20px;
  margin-bottom: 20px;
`;
