import { backendUrl } from 'config/constants';
import { MethodTypes } from 'hooks/useAxios';
import useForm from 'hooks/useForm';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

type TrainingUser = {
  email: string;
  password: string;
  userName: string;
};

export const Register: React.FC = () => {
  const [regsitered, setRegistered] = useState<boolean>(false);
  const { handleSubmit, handleChange, handleBlur, values, errors, axiosState } = useForm<
    TrainingUser,
    null | object
  >(`${backendUrl}registerUser`, MethodTypes.POST, {
    userName: '',
    email: '',
    password: '',
  });

  useEffect(() => {
    if (axiosState && axiosState.status === 200) {
      setRegistered(true);
    }
  }, [axiosState]);

  return (
    <Wrapper>
      {regsitered ? (
        <Main>You have recived a verification email. Please click on the verification link</Main>
      ) : (
        <Main>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label htmlFor="userName">UserName</Label>
              <Input
                type="text"
                placeholder="Enter username"
                name="userName"
                id="userName"
                value={values.userName}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.userName}
              />
              {errors.userName && <Error>{errors.userName}</Error>}
            </FormGroup>

            <FormGroup>
              <Label htmlFor="userEmail">Email address</Label>
              <Input
                type="email"
                placeholder="Enter email"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.email}
              />
              {errors.email && <Error>{errors.email}</Error>}
            </FormGroup>

            <FormGroup>
              <Label htmlFor="password">Password</Label>
              <Input
                type="password"
                placeholder="Password"
                name="password"
                id="password"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                isInvalid={!!errors.password}
              />
              {errors.password && <Error>{errors.password}</Error>}
            </FormGroup>

            <LoginButton type="submit">Register</LoginButton>
          </Form>
        </Main>
      )}
    </Wrapper>
  );
};

// Styled Components
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
`;

const Main = styled.div`
  width: 450px;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.85);
  border: 1px solid rgba(161, 108, 40, 1);
  border-radius: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const FormGroup = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  color: black;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input<{ isInvalid: boolean }>`
  width: 100%;
  padding: 8px;
  border: 1px solid ${({ isInvalid }) => (isInvalid ? 'red' : 'rgba(161, 108, 40, 1)')};
  border-radius: 5px;
  box-sizing: border-box;
`;

const Error = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 5px;
`;

const LoginButton = styled.button`
  background-color: white;
  color: black;
  margin-top: 20px;
  padding: 10px;
  border: 1px solid rgba(161, 108, 40, 1);
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;

  &:hover {
    background-color: rgba(161, 108, 40, 0.1);
  }
`;
