export enum SupportedLocales {
  'en-GB' = 'en-GB',
  'sv-SE' = 'sv-SE',
  'en-SE' = 'en-SE',
}

export enum allUserRoles {
  None = 'None',
  Admin = 'Admin',
  User = 'User',
  Support = 'Support',
  Developer = 'Developer',
}

export type SupportedLocale = keyof typeof SupportedLocales;
