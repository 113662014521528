import { TrainingFile, TrainingType } from 'pages/Upload';
import { UrlViewModel } from './Image';
import VideoScreenshot from './VideoScreenShot';
import styled from 'styled-components';
import { useState } from 'react';
import { PrimaryButton } from 'Shared/Components/Button';
import { Dropdown, DropdownItem, PreviewContainer, Edit, Remove } from 'Shared/Components';
import VideoPreview from './Video';
import ImagePreviewUp from './ImagePreview';

export type PreviewProps = {
  file: TrainingFile;
  src: UrlViewModel;
  name: string;
  onFileChange: (updatedFile: TrainingFile) => void;
  onDelete: (updatedFile: TrainingFile) => void;
};

export type MediaProps = {
  type: string;
  src: UrlViewModel;
  name: string;
};

enum FileType {
  Image = 'Image',
  Video = 'Video',
  Unsupported = 'Unsupported',
}

enum ImageExtension {
  jpg = 'jpg',
  jpeg = 'jpeg',
  png = 'png',
  gif = 'gif',
}

enum VideoExtension {
  mp4 = 'mp4',
  mov = 'mov',
  avi = 'avi',
}

const getFileType = (extension: string | undefined): FileType => {
  if (!extension) return FileType.Unsupported;
  if (extension in ImageExtension) return FileType.Image;
  if (extension in VideoExtension) return FileType.Video;
  return FileType.Unsupported;
};

export const Preview: React.FC<PreviewProps> = (props) => {
  const { src, file, name, onFileChange, onDelete } = props;
  const [fileName, setFileName] = useState<string>(name.split('.')[0]);
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const fileExtension = name.split('.').pop()?.toLowerCase();
  const fileType = getFileType(fileExtension);

  const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const slectedoption = event.target.value;

    const updatedFile = {
      ...file,
      trainingType: slectedoption as TrainingType, // or any other property you want to update
    };

    onFileChange(updatedFile);
  };

  const handleSave = () => {
    if (!file.trainingType) file.trainingType = TrainingType.Bicep;

    onFileChange(file);
    setIsEdit(false);
    setIsHovered(false);
  };

  const handleDelete = () => {
    onDelete(file);
  };

  const UnSupported = () => <>FileFormat not supported</>;
  const ImagePrev = () => (
    <ImagePreviewUp
      src={src}
      fileName={fileName}
      trainingType={file.trainingType}
      isEdit={isEdit}
    />
  );
  const VideoPrev = () => (
    <VideoPreview
      src={src}
      fileName={fileName}
      trainingType={file.trainingType}
      isEdit={isEdit}
      isHovered={isHovered}
    />
  );

  const Comp =
    fileType === FileType.Image
      ? ImagePrev()
      : fileType === FileType.Video
      ? VideoPrev()
      : UnSupported();

  return (
    <PreviewContainer
      isEdit={isEdit}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {!isEdit && (
        <ButtonContainer isVisible={isHovered}>
          <Edit onClick={() => setIsEdit(true)} />
          <Remove onClick={handleDelete} />
        </ButtonContainer>
      )}

      {Comp}

      {isEdit && (
        <InfoContainer>
          <Input
            type="text"
            value={fileName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFileName(e.target.value);
            }}
          />

          <h6>Choose excersize type</h6>

          <Dropdown onChange={handleSelect}>
            {Object.values(TrainingType).map((option) => (
              <DropdownItem key={option} value={option} selected={option === file.trainingType}>
                {option}
              </DropdownItem>
            ))}
          </Dropdown>

          <SaveButton onClick={handleSave}>Save</SaveButton>
        </InfoContainer>
      )}
    </PreviewContainer>
  );
};

const Input = styled.input`
  width: 100%;
  border-radius: 8px;
  min-height: 2rem;
  padding: 7px;
  border-color: #ccc;
  margin: 10px 0px;
`;

const SaveButton = styled(PrimaryButton)`
  width: 100%;
  margin: 10px 0px;
`;

const ButtonContainer = styled.div<{ isVisible: boolean }>`
  position: absolute;
  width: 100%;
  top: 0;
  flex-direction: row;
  padding: 10px;
  display: flex;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.7);
  transform: translateY(${({ isVisible }) => (isVisible ? '0' : '-20%')});
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: transform 0.3s ease, opacity 0.3s ease;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  z-index: 100000000000000000000000;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  text-align: center;
  align-items: center;
  margin-bottom: 20px;
  padding: 5px 10px;
`;
