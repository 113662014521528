import { useState } from 'react';
import { AxiosError, AxiosRequestConfig } from 'axios';
import axiosInstance from 'utilities/requestUtillity';

export interface AxiosState<T> {
  loading: boolean | null;
  error: unknown;
  data: T | null;
  status: number;
}

// req methods
export enum MethodTypes {
  POST = 'POST',
  PUT = 'PUT',
  GET = 'GET',
  DELETE = 'DELETE',
  PATCH = 'PATCH',
}

type CallAxiosFn = (config: AxiosRequestConfig) => Promise<void>;
type UseAxios = <T>() => [CallAxiosFn, AxiosState<T>]; // note the T. This allows us to send in back a response of a specific type.
const useAxios: UseAxios = <T>() => {
  const [axiosState, setAxiosState] = useState<AxiosState<T>>({
    loading: null,
    error: null,
    data: null,
    status: 0,
  });
  const callAxiosFn: CallAxiosFn = async (config) => {
    // state on loading
    setAxiosState({
      loading: true,
      error: null,
      data: null,
      status: 0,
    });

    try {
      const response = await axiosInstance({
        headers: {
          // Accept: 'application/json',
          ...(config.headers || {}),
        },
        ...config,
      });

      // call refresh and call the same token again.

      // state on success
      setAxiosState({
        loading: false,
        error: null,
        data: response.data,
        status: response.status,
      });
    } catch (e) {
      const axiosError = e as AxiosError;
      console.log('The e', e);
      // state on error
      setAxiosState({
        loading: false,
        error: e,
        data: null,
        status: axiosError.response.status,
      });
    }
  };
  return [callAxiosFn, axiosState];
};
export default useAxios;
